import React from "react";

import { AnimLayerBalanceCardList } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardList";

import { AnimLayerTransparentPhoneBg } from "../AnimLayerTransparentPhoneBg";

import type { AnimLayerBalanceCardListProps } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardList";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerPhoneBgBalanceCardsProps extends BoxProps {
  animLayerBalanceCards: Array<StoryblokBlok & AnimLayerBalanceCardListProps>;
}

export function AnimLayerPhoneBgBalanceCards({
  animLayerBalanceCards: animLayerBalanceCardsBlokArray,
  ...rest
}: AnimLayerPhoneBgBalanceCardsProps) {
  const [animLayerBalanceCards] = animLayerBalanceCardsBlokArray || [];

  return (
    <AnimLayerTransparentPhoneBg {...rest}>
      {animLayerBalanceCards && (
        <AnimLayerBalanceCardList {...animLayerBalanceCards} />
      )}
    </AnimLayerTransparentPhoneBg>
  );
}
