import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { Image } from "~components/Image";

import { SvgLogoMockClient } from "~animations/__layers__/logos/SvgLogoMockClient";

import { SvgTruelayerConnection } from "../../SvgTruelayerConnection";

import type { BoxProps } from "~components/Box";
import type { StoryblokFieldMedia } from "~types/storyblok.types";

export interface AnimLayerTrueLayerConnectionProps extends BoxProps {
  image: StoryblokFieldMedia;
}

const FRAME_PROPS: BoxProps = {
  borderRadius: "sm",
  border: "default",
  className: variantBackgroundColor.background_white,
  width: "phoneSpacing6",
  height: "phoneSpacing6",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "phoneSpacing1",
};

export function AnimLayerTrueLayerConnection({
  image,
  ...rest
}: AnimLayerTrueLayerConnectionProps) {
  return (
    <Box
      {...rest}
      alignItems="center"
      display="flex"
      gap="phoneSpacing1"
      marginY="phoneSpacing3"
      justifyContent="center"
      data-motion={ANIMATED.secondary}
    >
      <Box {...FRAME_PROPS}>
        <SvgLogoMockClient />
      </Box>

      <SvgTruelayerConnection />

      <Box {...FRAME_PROPS}>{image && <Image image={image} />}</Box>
    </Box>
  );
}
