import React from "react";

import { Box } from "~components/Box";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import * as styles from "./styles.css";

import type { ReactNode } from "react";
import type { BoxProps } from "~components/Box";

export interface AnimLayerTransparentPhoneBgProps extends BoxProps {
  children: ReactNode;
}

export function AnimLayerTransparentPhoneBg({
  children,
  ...rest
}: AnimLayerTransparentPhoneBgProps) {
  return (
    <AnimatedElement animationLevel="primary">
      <Box
        aspectRatio="mobile"
        className={styles.mockPhoneBackground}
        width="gridSpan3"
        padding="phoneSpacing1"
        borderRadius="sm"
        opacity="0.8"
        {...rest}
      >
        {children}
      </Box>
    </AnimatedElement>
  );
}
