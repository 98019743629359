import React from "react";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerUiCenteredTextProps extends StoryblokBlok, BoxProps {
  title?: string;
  description?: string;
}

export function AnimLayerUiCenteredText({
  title,
  description,
  ...rest
}: AnimLayerUiCenteredTextProps) {
  if (!title && !description) return null;

  return (
    <StoryblokEditable {...rest}>
      <Box {...rest}>
        {title && (
          <Box
            data-motion={ANIMATED.secondary}
            fontWeight="semibold"
            marginBottom="phoneSpacing2"
            marginX="auto"
            textAlign="center"
            textAppearance="phone_h6"
          >
            {title}
          </Box>
        )}
        {description && (
          <Box
            data-motion={ANIMATED.secondary}
            marginX="auto"
            // maxWidth="75%"
            textAlign="center"
            textAppearance="phone_text_md"
          >
            {description}
          </Box>
        )}
      </Box>
    </StoryblokEditable>
  );
}
