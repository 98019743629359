import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { AnimLayerTransactionList } from "~animations/__layers__/mock_ui_components/AnimLayerTransactionList";

import { AnimLayerTransparentPhoneBg } from "../AnimLayerTransparentPhoneBg";

import type { AnimLayerTransactionListProps } from "~animations/__layers__/mock_ui_components/AnimLayerTransactionList";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerPhoneBgTransactionListProps extends BoxProps {
  animLayerTransactionList: Array<
    StoryblokBlok & AnimLayerTransactionListProps
  >;
}

export function AnimLayerPhoneBgTransactionList({
  animLayerTransactionList: animLayerTransactionListBlokArray,
  ...rest
}: AnimLayerPhoneBgTransactionListProps) {
  const [animLayerTransactionList] = animLayerTransactionListBlokArray || [];

  return (
    <AnimLayerTransparentPhoneBg {...rest}>
      {animLayerTransactionList && (
        <AnimLayerTransactionList
          className={variantBackgroundColor.background_white}
          borderRadius="sm"
          {...animLayerTransactionList}
        />
      )}
    </AnimLayerTransparentPhoneBg>
  );
}
