import React from "react";

import { faCheck } from "@fortawesome/sharp-regular-svg-icons/faCheck";
import clsx from "clsx";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";

import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";

export interface AnimLayerUiRadioButtonProps extends BoxProps {
  isChecked?: boolean;
}

export function AnimLayerUiRadioButton({
  isChecked,
  ...rest
}: AnimLayerUiRadioButtonProps) {
  if (isChecked) {
    return (
      <IconFontAwesome
        {...rest}
        className={clsx(styles.base, styles.checked)}
        icon={faCheck}
        size="2xs"
      />
    );
  }

  return <Box {...rest} className={clsx(styles.base, styles.unChecked)} />;
}
