import React, { useContext } from "react";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { PageQueryContext } from "~context/PageQueryContext";

import { AnimLayerFakeButton } from "../AnimLayerFakeButton";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerUiBottomCheckoutProps
  extends BoxProps,
    StoryblokBlok {
  amount: number;
  amountTitle: string;
  ctaText: string;
}

export function AnimLayerUiBottomCheckout({
  amount,
  amountTitle,
  ctaText,
  ...rest
}: AnimLayerUiBottomCheckoutProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  return (
    <StoryblokEditable {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="phoneSpacing3"
        {...rest}
      >
        <Box data-motion={ANIMATED.secondary}>
          <Box textAppearance="phone_text_sm" color="text_lowContrast">
            {amountTitle}
          </Box>
          <Box fontWeight="semibold">
            {formatCurrencyString({
              amount,
              lang,
            })}
          </Box>
        </Box>
        <AnimLayerFakeButton data-motion={ANIMATED.secondary}>
          {ctaText}
        </AnimLayerFakeButton>
      </Box>
    </StoryblokEditable>
  );
}
