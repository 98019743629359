import React from "react";

import { faChevronDown } from "@fortawesome/sharp-regular-svg-icons/faChevronDown";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";

import type { BoxProps } from "~components/Box";

export interface AnimLayerUiMockCollapsibleProps extends BoxProps {
  title: string;
}

export function AnimLayerUiMockCollapsible({
  title,
  ...rest
}: AnimLayerUiMockCollapsibleProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      data-motion={ANIMATED.secondary}
      className={variantBackgroundColor.background_sand}
      padding="phoneSpacing2"
      borderRadius="sm"
      {...rest}
    >
      <Box textAppearance="phone_text_sm" fontWeight="semibold">
        {title}
      </Box>

      <IconFontAwesome
        size="xs"
        icon={faChevronDown}
        color="text_lowContrast"
      />
    </Box>
  );
}
