import React from "react";

import { AnimLayerIconList } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";

import { AnimLayerTransparentPhoneBg } from "../AnimLayerTransparentPhoneBg";

import type { AnimLayerIconListProps } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerPhoneBgIconListProps extends BoxProps {
  animLayerIconList: Array<StoryblokBlok & AnimLayerIconListProps>;
}

export function AnimLayerPhoneBgIconList({
  animLayerIconList: animLayerIconListBlokArray,
  ...rest
}: AnimLayerPhoneBgIconListProps) {
  const [animLayerIconList] = animLayerIconListBlokArray || [];

  return (
    <AnimLayerTransparentPhoneBg {...rest}>
      {animLayerIconList && (
        <AnimLayerIconList appearance="card" {...animLayerIconList} />
      )}
    </AnimLayerTransparentPhoneBg>
  );
}
