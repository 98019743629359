import React from "react";

import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";

interface AnimLayerUiCardTitleProps {
  title?: string;
  ctaText?: string;
}

export function AnimLayerUiCardTitle({
  title,
  ctaText,
}: AnimLayerUiCardTitleProps) {
  if (!title && !ctaText) return null;

  return (
    <Box
      textAppearance="phone_text_sm"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      data-motion={ANIMATED.secondary}
      marginY="phoneSpacing2"
      fontWeight="semibold"
    >
      {title && <Box color="text_highContrast">{title}</Box>}
      {ctaText && (
        <Box __color={colorTokens_latest.Secondary.Water_azure[150].value}>
          {ctaText}
        </Box>
      )}
    </Box>
  );
}
